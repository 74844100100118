import _ from 'lodash'
import { computed, isRef } from 'vue'
import { getObjectsDiffKeys  } from 'KPackages/helpers/objects'
import websiteConfig from "./websiteConfig";

const addMissingData = (block, storeBlocks) => {
  // aggiunge ai content e settings del blocco le eventuali lingue non presentati
  // aggiunge updateBlockWithConfigDefaults
  let _block = _.cloneDeep(block);
  _block = addMissingLanguageData(_block, _block.permissions.service, storeBlocks);
  if (!_.isEmpty(_block.content)) {
    updateBlockWithConfigDefaults(_block, _block.permissions.service, _block.type, 'content', false)
  }
  if (!_.isEmpty(_block.settings)) {
    updateBlockWithConfigDefaults(_block, _block.permissions.service, _block.type, 'settings', false)
  }
  return _block
}

const addMissingLanguageData = (block, service, storeBlocks) => {
  // block può esser sia un AdminBlock che un Block
  let _block = _.cloneDeep(block);
  var languages = getBlockLanguages(_block, storeBlocks);
  let multilanguageContentFields = websiteConfig.getBlockTypeContentMultilanguage(service, _block.type)
  let multilanguageSettingsFields = websiteConfig.getBlockTypeSettingsMultilanguage(service, _block.type)
  initEmptyMultiLanguageFields(_block.settings, languages, multilanguageSettingsFields)
  initEmptyMultiLanguageFields(_block.content, languages, multilanguageContentFields)
  return _block;
}

const areBlockDataChanged = (block, presentationBlock, storeBlocks) => {
  // verifica se i cotenuti o i setting tra i due blocchi
  // block e presentationBlock sono cambiati
  if (presentationBlock === undefined) {
    return true;
  }
  // faccio update della presentationBlock coi valori delle lingue per fare il confronto
  // con l'admin block che ha già questi valori, così da evitare che il blocco risulti in draft solo
  // perché è stato aggiunta una lingua nel blocco newsroom 
  presentationBlock = addMissingLanguageData(presentationBlock, block.permissions.service, storeBlocks)
  var _b = getBlockContentSpecificData(block)
  var _i = getBlockContentSpecificData(presentationBlock)
  return !_.isEqual(_b, _i)
}

const getAvailableChildrenTypes = (block) => {
  const requiredBlocksTypes = websiteConfig.getBlockChildrenRequiredTypes(block.permissions.service, block.type)
  return computed(() => {
    var childrenTypes = websiteConfig.getBlockChildrenTypes(block.permissions.service, block.type)
    var ret = _.filter(childrenTypes, function(ctype) {
      return !requiredBlocksTypes.includes(ctype)
    });
    return ret;
  })
}

const getBlockContentSpecificData = (block) => {
  if (!_.isObject(block)) {
    return {}
  }
  // controllo si i dati di un blocco sono cambiati
  // escludendo l'updated_at, perché altrimenti modificando un dato e ripristinandone il valore
  // restituirebbe sempre true
  return {
    content: block.content,
    settings: block.settings,
  }
}

const getBlockLanguages = (_block, storeBlocks) => {
  const convertToList = (data) => {
    // puts the default language at the beginning of the languages list
    return [data.default].concat(data.additional)
  }
  if (_block === undefined) { // tentativo di fix errore in lista storie nella home di newsroom
    return [];
  }
  if (_.isObject(_block.settings) &&
      _block.settings.languages !== undefined &&
      (_block.settings.languages.default !== 'en' || _block.settings.languages.additional.length > 0)
  ) {
    return convertToList(_block.settings.languages)
  }
  if (_block.type === 'newsroom') {
    if (_.isObject(_block.settings)) {
      return convertToList(_block.settings.languages)
    }
    return []
  } else {
    if (_.isString(_block.parent)) {
      return getBlockLanguages(storeBlocks[_block.parent], storeBlocks)
    } else {
      // root ha solo en
      return ['en']
    }
  }
}


const getNumOfChildren = function(children, blockType) {
  // returns the num of children per blockType
  return _.filter(children, function(c) {
    return c.type === blockType
  }).length
}

const getBlockAdminComponent = function(block) {
  if (_.isEmpty(block)) {
    return null
  }
  var ret = websiteConfig.getBlockTypeDirectives(block.permissions.service, block.type).admin;
  return ret;
}

const getOrderedChildren = function(orderedIdList, children) {
  let ordered = [];
  orderedIdList.forEach(orderedItem => {
    for (let child of children) {
      if (child.id === orderedItem) {
        ordered.push(child);
        break;
      }
    }
  });

  return ordered
}

const getPresentationBlockComponent = function(block) {
  if (_.isEmpty(block)) {
    return null
  }
  return websiteConfig.getBlockTypeDirectives('newsroom', block.type).presentation
}

const getMultiLanguageFieldName = (fieldName, language) => {
  // restituisce il field name da usare nei componenti in lingua
  if (!_.isString(language)) {
    return fieldName
  }
  var ret = fieldName + '.' + language;
  return ret;
}

const getSettingsBlockComponent = function(block) {
  if (_.isEmpty(block)) {
    return null
  }
  var settingsDirective = websiteConfig.getBlockTypeDirectives(block.permissions.service, block.type).settings;
  if (_.isObject(settingsDirective)) {
    return settingsDirective.tag;
  } else {
    return settingsDirective;
  }
}

const getFieldsGroupedByLanguage = (content, language) => {
  var obj = {}
  for (var key in content) {
    if (
      content[key] !== undefined && 
      content[key] !== null && 
      content[key][language] !== undefined
    ) {
      obj[key] = content[key][language]
    }
  }
  return obj
}
export const initEmptyMultiLanguageField = (fieldDraft, languages) => {
  // se il fieldDraft (es. setup.draftData.name) è null o manca di alcuni valori in lingua
  // inizializzo i suoi valori a '' (es. setup.draftData.name.it = '')
  var langList = languages;
  if (isRef(languages)) {
    langList = languages.value;
  }
  for (var i = 0; i < langList.length; i++) {
    var lang = langList[i]
    if(isRef(fieldDraft)) {
      var _val = _.get(fieldDraft.value, lang, null);
      if (_val === null) {
        _.set(fieldDraft.value, lang, '')
      }
    } else {
      var _val = _.get(fieldDraft, lang, null);
      if (_val === null) {
        _.set(fieldDraft, lang, '')
      }
    }
  }
}

export const initEmptyMultiLanguageFields = (draft, languages, fields) => {
  if (fields === undefined) {
    return
  }
  if (isRef(fields)) {
    fields = fields.value;
  }
  // se alcuni field in lingua sono mancanti li inizializza a vuoto
  for (var i = 0; i < languages.length; i++) {
    for (var y = 0; y < fields.length; y++) {
      var path = fields[y] + '.' + [languages[i]];
      if(isRef(draft)) {
        if (_.get(draft.value, path, null) === null) {
          _.set(draft.value, path, '')
        }
      } else {
        if (_.get(draft, path, null) === null) {
          _.set(draft, path, '')
        }
      }
    }
  }
}

export const isTheSlugsPath = (block, slugs_path) => {
  //
  // Tenere ALLINEATO con website.models.is_the_slugs_path
  //
  var loop = function(_block, _slugs_path) {
    // se non è lo stesso slug restituisco null
    if(_block.settings.slugs.value !== _slugs_path[_slugs_path.length - 1]) {
      return false;
    }
    // se è stesso slug, lo clono e rimuovo primo elemento
    var slugs = [..._slugs_path]
    slugs = slugs.pop()
    // se ci sono ancora slug da controllare
    if (slugs.length > 0) {
        var parent_page = getNearestParentPageBlock(block.id)
        if (parent_page !== null) {
            return loop(parent_page, slugs)
        } else {
          return false
        }
    } else {
      return true
    }
  }
  return loop(block, slugs_path)

}

export const seekNearestParentBlock = (blockId, blocks, propertiesToMatch) => {
  // returns the nearest parent that match propertiesToMatch ({group: 'page' oppure '!== undefined' ) block else null
  if(_.isEmpty(propertiesToMatch) || propertiesToMatch === undefined) {
    console.error("seekNearestParentBlock missing propertiesToMatch")
  }
  var seek = function (blockId) {
    var block = blocks[blockId]
    if (_.isString(block.parent)) {
      var parent = blocks[block.parent]
      if (parent === undefined) {
        return null
      }
      for(var k in propertiesToMatch) {
        var objectValue = _.get(parent, k, undefined)
        var desiredValue = propertiesToMatch[k];
        var found = true;
        if (_.startsWith(desiredValue, '!=')) {
          var matchingString = "objectValue" + desiredValue
          if (!eval(matchingString)) {
            found = false
          }
        } else {
          if (objectValue !== desiredValue) {
            found = false;
          }
        }
        if (!found) {
          return seek(block.parent)
        }
      }
      return parent;
    } else {
      return null;
    }
  }
  return seek(blockId)
}
  
const updateBlockWithConfigDefaults = (objToUpdate, service, blockType, configPath, updateInStore, store) => {
  // update the keys of the configPath (es. settings ) of an obj with the default values of the keys in the same path taken from the config
  // it is a way to inizialize the same path if some keys are missing, i.e. when we add new keys and the saved blocks has not yet them
  // updateInStore è per aggiornare il blocco nello store, è a false quando non si tratta di un vero blocco ma serve solo ad aggiornare un oggetto, come nella creazione iniziale di una pagina Newsroom o Storia
  if (updateInStore === undefined) {
    updateInStore = true;
  }
  const configBlockType = websiteConfig.getBlockType(service, blockType);
  const configPathObj = _.get(configBlockType, configPath)
  var blockPathObj = _.get(objToUpdate, configPath)
  var newBlock;
  if(blockPathObj === undefined) {
    newBlock = _.cloneDeep(objToUpdate)
    _.set(newBlock, configPath, configPathObj)
  } else {
    var diffs = getObjectsDiffKeys(blockPathObj, configPathObj)
    if (diffs.missing_from_first.length > 0) {
      if (updateInStore) { // se è da aggiornare nello store non posso modificare una mutation e la clono per poi modificarla alla fine
        newBlock = _.cloneDeep(objToUpdate)
      } else {
        newBlock = objToUpdate;
      }
      blockPathObj = _.get(newBlock, configPath)
      for (var i = 0; i < diffs.missing_from_first.length; i++) {
        var missingPath = diffs.missing_from_first[i]
        _.set(blockPathObj, missingPath, _.get(configPathObj, missingPath))
      }
    }
  }
  if (newBlock !== undefined && updateInStore) {
    store.dispatch('website/createOrUpdateBlockInStore', newBlock);
  }
}


export default {
  addMissingData,
  areBlockDataChanged,
  getAvailableChildrenTypes,
  getBlockAdminComponent,
  getBlockContentSpecificData,
  getBlockLanguages,
  getFieldsGroupedByLanguage,
  getMultiLanguageFieldName,
  getNumOfChildren,
  getOrderedChildren,
  getPresentationBlockComponent,
  getSettingsBlockComponent,
  initEmptyMultiLanguageField,
  initEmptyMultiLanguageFields,
  isTheSlugsPath,
  seekNearestParentBlock,
  updateBlockWithConfigDefaults,
}