export const all = state => state.all

export const getByUuid = (state, getters, rootState, rootGetters) => (uuid) => {
  if (uuid in getters.all) {
    return getters.all[uuid]
  } else {
  	return null
  }
}

export const getFileNameByUuid = (state, getters, rootState, rootGetters) => (uuid) => {
  var media = getters.all[uuid]
  if (media !== undefined) {
    var fullUrl = media.url;
    if (fullUrl !== '' && fullUrl !== undefined && fullUrl !== null) {
      var arr = fullUrl.split('/')
      return arr[arr.length-1]
    }
  }
  return ''
}