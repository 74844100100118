<template>
  <router-view :key="$route.fullPath"/>
</template>

<script>
export default {
  //setup(props, context) {
  //  console.log("presentationApp")
  //  return {}
  //}
}
</script>

<style>
</style>
