const serviceDomain = process.env.VUE_APP_WEBSITE_DOMAIN;
if (serviceDomain === undefined) {
  throw("process.env.VUE_APP_WEBSITE_DOMAIN not defined")
}
const serviceUrl = location.protocol + '//' + serviceDomain;

const serverUrls = { // url di django
  "api_admin_block": serviceUrl + '/api/admin/block/:id',
  "api_admin_block_children": serviceUrl + '/api/admin/block/:id/children',
  "api_admin_blocks": serviceUrl + '/api/admin/blocks/',
  "api_admin_publish_block": serviceUrl + '/api/admin/publish-block/:id',
  "api_block_by_slug": serviceUrl + '/api/block/:service/:workspace/:slugs',
  "api_block": serviceUrl + '/api/block/:id',
  "api_block_children": serviceUrl + '/api/block/:id/children',
  "api_blocks": serviceUrl + '/api/blocks',
} 

const spaUrls = { // url vue
}

export default {
  server: serverUrls,
  spa: spaUrls
};
