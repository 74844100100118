/* 
A collection of functions to read the config
*/

import blockUtils from "./blockUtils";

const config = {
  base: {
    templates: {
      'page': {
        directive: 'page',
        contentAccess: 'inline',
        publishButtonVisible: true,
        settingsAccess: 'inline',
      },
      'page-list': {
        directive: 'page-list',
        linkToChildrenTypes: null,
        linkToParentsTypes: null,
      },
      'page-settings-link': {
        base: 'page',
        publishButtonVisible: true,
        settingsAccess: 'link',
      },
      'settings-modal': {} // open the settings modal
    },
    blockTypes: [
      /*{
        type: 'pages-manager', // il tipo di blocco
        children: { // i blockTypes che possono esserci in questo blocco
          types: [ 'story' ],
          config: { // additional configurations for children blocks
            story: {
              maxNum: 1,
              minNum: 1, // make it required
            }
          }
        },
        directives: {
          admin: 'pages-manager-block' o obj { tag: 'page-block', linkToChildrenPages: true,  linkToParentPages: true },
          presentation: 'string',
          settings: 'pages-manager-settings',
        },
        group: 'page', // page | content se un blocco è di tipo pagina o contenuto interno ad una pagina
        labels: { // le sue labels
          plural: 'Newsrooms',
          singular: 'Newsroom',
        },
        settings: { // i suoi settings
          footerBackground: 'rgb(255, 255, 255)',
          footerLinks: 'rgb(0, 0, 0)',
          headerBackground: 'rgb(255, 255, 255)',
          headerLinks: 'rgb(0, 0, 0)',
          logo: null,
          name: {
            en: 'Page'
          },
          siteBodyBackground: 'rgb(255, 255, 255)',
        },
        templates: {
          list: '', // quale template usare nelle eventuali page list di quel tipo
          instance: '', // quale template usare all'edit di una istanza di quel tipo
        }
      },*/
      {
        type: 'cover',
        children: {
          types: [ 'text' ],
          configs: {
            'text': {
              maxNum: 1,
              minNum: 1,
            }
          }
        },
        content: {
          childrenOrder: [],
          mediaId: null
        },
        directives: {
          admin: 'cover-block',
          presentation: 'multi-language-cover-block',
          settings: 'cover-settings',
        },
        group: 'content',
        labels: {
          plural: 'Covers',
          singular: 'Cover',
        },
        settings: {
          cropToScreen: true,
          imageVerticalAlignement: 'center',
          textPaddingPercentage: "3",
        }
      },
      {
        type: 'file',
        content: {
          description: null,
          mediaId: null,
        },
        contentMultilanguage: [ 'mediaId', 'description' ],
        directives: {
          admin: 'multi-language-file-block',
          presentation: 'multi-language-file-block',
          settings: null,
        },
        group: 'content',
        labels: {
          plural: 'Files',
          singular: 'File',
        },
        settings: null,
      },
      {
        type: 'gallery',
        children: {
          types: [ 'gallery-image' ]
        },
        content: {
          childrenOrder: []
        },
        directives: {
          admin: 'gallery-block',
          presentation: 'multi-language-gallery-block',
          settings: 'gallery-settings',
        },
        group: 'content',
        labels: {
          plural: 'Hi-Res Galleries',
          singular: 'Hi-Res Gallery',
        },
        settings: {
          categories: []
        },
        settingsMultilanguage: [ 'categories' ]
      },
      {
        type: 'gallery-image',
        content: {
          caption: null,
          categoryId: '',
          mediaId: null,
        },
        contentMultilanguage: [ 'caption' ],
        directives: {
          admin: 'gallery-image-block',
          settings: null,
        },
        group: 'content',
        labels: {
          plural: 'Gallery Images',
          singular: 'Gallery Image',
        },
        settings: null
      },
      {
        type: 'image',
        content: {
          caption: null,
          mediaId: null
        },
        contentMultilanguage: [ 'mediaId', 'caption' ],
        directives: {
          admin: 'multi-language-image-block',
          presentation: 'multi-language-image-block',
          settings: 'image-settings',
        },
        group: 'content',
        labels: {
          plural: 'Images',
          singular: 'Image',
        },
        settings: {
          fullscreen: false,
          hAlign: 'center',
          width: null,
          height: null
        }
      },
      {
        type: 'page',
        children: {
          types: [ 'cover', 'text', 'image', 'gallery', 'video', 'file', ],
        },
        content: {
          childrenOrder: [],
        },
        directives: {
          admin: 'page-block',
          presentation: 'page-block',
          settings: 'page-settings',
        },
        group: 'page',
        labels: {
          plural: 'Pages',
          singular: 'Page',
        },
        templates: {
          instance: "page"
        },
        settings: {
          name: {
            en: 'Page'
          },
          slug: {
            manuallySet: false,
            value: 'page'
          },
          customDomain: ''
        },
        settingsMultilanguage: [ 'name' ],
      },
      {
        type: 'pages-manager',
        children: {
          types: [ 'page' ],
          addNewItemsAtTop: false, 
          configs: {
            page: {
              minNum: 1
            }
          }
        },
        content: {
          childrenOrder: [],
        },
        directives: {
          admin: 'pages-manager-block',
          settings: null,
        },
        editButtonTitle: 'Edit',
        group: 'content',
        labels: {
          plural: 'Pages Managers',
          singular: 'Pages Manager',
        },
        settings: null
      },
      {
        type: 'root',
        children: {
          types: [ 'pages-manager' ],
          configs: {
            'pages-manager': {
              maxNum: 1,
              minNum: 1,
            }
          }
        },
        directives: {
          admin: 'root-block',
          presentation: null,
          settings: 'root-settings', // serve solo per far apparire la modale
        },
        group: 'page',
        /*labels: { //  se ci sono appare la colonna per Root nell'elenco dei newsgroup
          plural: 'Roots',
          singular: 'Root',
        },*/
        publishedByDefault: true,
        settings: {}, // serve solo per far apparire la modale
        templates: {
          list: 'page-list',
          instance: 'settings-modal'
        }
      },
      {
        type: 'text',
        content: {
          text: null,
        },
        contentMultilanguage: [ 'text' ],
        directives: {
          admin: 'multi-language-text-block',
          presentation: 'multi-language-text-block',
          settings: null,
        },
        group: 'content',
        labels: {
          plural: 'Text',
          singular: 'Text',
        },
        settings: null
      },
      {
        type: 'video',
        content: {
          description: null,
          downloadLink: null,
          url: null
        },
        contentMultilanguage: [ 'description', 'downloadLink', 'url' ],
        directives: {
          admin: 'multi-language-video-block',
          presentation: 'multi-language-video-block',
          settings: 'video-settings',
        },
        group: 'content',
        labels: {
          plural: 'Videos',
          singular: 'Video',
        },
        settings: {
          enable_embed: false
        }
      },
    ],
  },
  services: {
    newsroom: {
      labels: {
        singular: 'Newsroom',
      },
      startsWithType: 'story',
      templates: {
        'newsroom-page-list': {
          base: 'page-list',
          linkToChildrenTypes: true,
        },
        'story-page-list': {
          base: 'page-list',
          linkToParentsTypes: true,
        },
      },
      blockTypes: [
        {
          type: 'newsroom',
          base: 'page',
          children: {
            types: [ 'story-pages-manager' ],
            configs: {
              'story-pages-manager': {
                maxNum: 1,
                minNum: 1, 
              }
            }
          },
          directives: {
            //admin: { tag: 'page-list', linkToChildrenPages: true },
            presentation: 'newsroom-block',
            settings: 'newsroom-newsroom-settings',
          },
          labels: {
            plural: 'Newsrooms',
            singular: 'Newsroom',
          },
          settings: { //
            footerBackground: 'rgb(255, 255, 255)',
            footerLinks: 'rgb(0, 0, 0)',
            headerBackground: 'rgb(255, 255, 255)',
            menuBackground: 'rgb(49, 49, 49)',
            languages: {
              additional: [],
              default: 'en',
            },
            logo: null,
            logoWidth: '',
            searchEngineVisibility: true,
            siteBodyBackground: 'rgb(255, 255, 255)',
          },
          templates: {
            list: 'newsroom-page-list',
            instance: 'settings-modal'
          }
        },
        {
          type: 'newsroom-pages-manager',
          base: 'pages-manager',
          children: {
            types: [ 'newsroom' ],
            addNewItemsAtTop: true,
            configs: {
              newsroom: {
                maxNum: 1,
                minNum: 1,
              }
            }
          },
          directives: {
            settings: null,
          },
          draggable: false,
          editButtonTitle: 'Manage',
          labels: {
            plural: 'Newsrooms Pages Manager',
            singular: 'Newsroom Pages Manager',
          },
          settings: null
        },
        {
          type: 'root',
          base: 'root',
          children: {
            types: [ 'newsroom-pages-manager' ],
            configs: {
              'newsroom-pages-manager': {
                maxNum: 1,
                minNum: 1,
              }
            }
          },
          templates: {
            list: 'page-list',
          }
        },
        {
          type: 'story',
          base: 'page',
          children: {
            types: [ 'pages-manager' ],
            configs: {
              'pages-manager': {
                maxNum: 1,
                minNum: 1,
              }
            }
          },
          directives: {
            presentation: 'story-block',
            settings: 'newsroom-story-settings'
          },
          labels: {
            plural: 'Stories',
            singular: 'Story',
          },
          settings: {
            category: null,
            image: null,
            publish_datetime: {
              datetime: null,
              timezone: 'Etc/UTC'
            },
          },
          templates: {
            list: 'story-page-list',
            instance: 'page-settings-link'
          }
        },
        {
          type: 'story-pages-manager',
          base: 'pages-manager',
          children: {
            types: [ 'story' ],
            addNewItemsAtTop: true,
            configs: {
              story: {
                minNum: 1
              }
            }
          },
          labels: {
            plural: 'Stories',
            singular: 'Story',
          },
          settings: null
        },
      ],
    }
  }
}


function canAddChildTypeToBlockType(service, childType, blockType, children) {
  // can a new block of type = type be added to the block = block considering the children = children already present in block?
  var maxNum = getTypeNumInBlockType('maxNum', service, childType, blockType)
  if (maxNum === undefined) {
    return true;
  }
  // se c'è un numero massimo controllo se in children c'è già quel childType
  var alreadyAdded = false;
  if (children !== null) {
    alreadyAdded = blockUtils.getNumOfChildren(children, childType);
  }
  return alreadyAdded < maxNum;
}

function canDeleteChildTypeFromBlockType(service, childType, blockType, children) {
  var minNum = getTypeNumInBlockType('minNum', service, childType, blockType)
  if (minNum === undefined) {
    return true;
  }
  // se c'è un numero minimo controllo se in children c'è già quel childType
  var alreadyAdded = blockUtils.getNumOfChildren(children, childType);
  return alreadyAdded > minNum;
}

function getAvailableChildrenTypes(service, blockType, children) {
  const childrenTypes = getBlockChildrenTypes(service, blockType);
  if (children === null) {
    return childrenTypes;
  } else {
    var ret = []
    for (var i = 0; i < childrenTypes.length ; i++) {
      if (canAddChildTypeToBlockType(service, childrenTypes[i], blockType, children)) {
        ret.push(childrenTypes[i])
      }
    }
    return ret;
  }
}

function getBlockChildren(service, type) {
  const _bt = getBlockType(service, type);
  return _bt.children;
}
function getBlockChildrenBlockTypes(service, type) {
  //console.log("getBlockChildrenBlockTypes", service, type)
  var childrenTypes = getBlockChildrenTypes(service, type)
  var ret = []
  for (var i = 0; i < childrenTypes.length; i++) {
    ret.push(getBlockType(service, childrenTypes[i]))
  }
  return ret;
}

function getBlockChildrenAddNewItemsAtTop(service, type) {
  const _btc = getBlockChildren(service, type);
  return _btc.addNewItemsAtTop;
}
function getBlockChildrenTypes(service, type) {
  const _btc = getBlockChildren(service, type);
  return _btc.types;
}
function getBlockChildrenConfigs(service, type) {
  const _btc = getBlockChildren(service, type);
  return _btc.configs
}
function getBlockChildrenRequiredTypes(service, type) {
  var ret = [];
  const childrenTypes = getBlockChildrenTypes(service, type);
  const childrenConfigs = getBlockChildrenConfigs(service, type)
  if (childrenConfigs === undefined) {
    return []
  }
  for (var i=0; i< childrenTypes.length; i++) {
    var childName = childrenTypes[i];
    if (childrenConfigs[childName] !== undefined) {
      if (childrenConfigs[childName]['minNum'] !== undefined) {
        ret.push(childName)
      }
    }
  }
  return ret;
}

function getBlockParentsBlockTypes(service, type) {
  //console.log("getBlockParentsBlockTypes", service, type)
  var ret = []
  for (var i = 0; i < config.services[service].blockTypes.length; i++) {
    var _bt = config.services[service].blockTypes[i];
    var childrenTypes = _.get(_bt, 'children.types', []);
    if (childrenTypes.includes(type)) {
      ret.push(getBlockType(service, _bt.type))
    }
  }
  return ret;
}

function getBlockTemplates(service, type) {
  var block = getBlockType(service, type);
  for (var k in block.templates) {
    var template = getTemplate(service, block.templates[k])
    if ( !_.isEmpty(template)) {
      block.templates[k] = template; 
    }
  }
  return block.templates;
}

function getBlockType(service, type) {
  var getFromBase = (type) => {
    var foundTypes = _.filter(config.base.blockTypes, function(t) {
      return t.type === type;
    })
    if (foundTypes.length > 0) {
      var ret = _.cloneDeep(foundTypes[0])
      return ret;
    }
    return null
  }
  var getFromService = (type, service) => {
    var foundTypes = _.filter(config.services[service].blockTypes, function(t) {
      return t.type === type;
    })
    if (foundTypes.length > 0) {
      return _.cloneDeep(foundTypes[0])
    }
    return null
  }

  var blockType = getFromService(type, service)
  // se esiste tra i service
  if (blockType !== null) {
    // se ha un base
    if (_.isString(blockType.base)) {
      var baseBlockType = getFromBase(blockType.base);
      if (baseBlockType === null) {
        alert("BlockType schema not found in base for type " + blockType.base)
      } else {
        // se esiste il base
        var ret = mergeDeep(baseBlockType, blockType)
        //console.log("ret (with base field)", ret)
        return ret;
      }
    }
  }
  // se non esiste tra i service o non ha un base
  var ret = getFromBase(type);
  if (ret !== null) {
    //console.log("ret (no base field)", ret)
    return ret
  } else {
    alert("BlockType schema not found in base or " + service + " for type " + type)
  }
}

function getBlockTypeContent(service, type) {
  const _bt = getBlockType(service, type);
  return _bt.content;
}
function getBlockTypeContentMultilanguage(service, type) {
  const _bt = getBlockType(service, type);
  return _bt.contentMultilanguage;
}
function getBlockTypeSettingsMultilanguage(service, type) {
  const _bt = getBlockType(service, type);
  return _bt.settingsMultilanguage;
}

//function getBlockTypeDirectiveAdmin(service, type) {
//  var directive = getBlockTypeDirectives(service, type);
//  if (_.isObject(directive)) {
//    return directive.tag
//  }
//  return directive
//}

function getBlockTypeDirectives(service, type) {
  const _bt = getBlockType(service, type);
  var ret = _bt.directives;
  if (ret === undefined) { alert("Settings schema not found in " + service + " for type " + type)}
  return ret;
}

function getBlockTypeGroup(service, type) {
  const _bt = getBlockType(service, type);
  var ret = _bt.group;
  if (ret === undefined) { alert("Settings schema not found in " + service + " for type " + type)}
  return ret;
}

function getBlockTypeLabels(service, type, returnTypeIfNoLabels) {
  var block = getBlockType(service, type);
  var ret = block.labels;
  if (ret === undefined && returnTypeIfNoLabels === true) {
    return { 'singular': block.type, 'plural': block.type }
  }
  return ret;
}

function getBlockTypeSettings(service, type) {
  const _bt = getBlockType(service, type);
  var ret = _bt.settings;
  if (ret === undefined) { alert("Settings schema not found in " + service + " for type " + type)}
  return ret;
}


function getDefaultGalleryImageContentCategoryId (service) {
  const _bt = getBlockType(service, 'gallery-image')
  return _bt.content.categoryId
}

function getEmptyBlock(service, workspace, type, parentId) {
  if (parentId === undefined) {
    parentId = null
  }
  var content = getBlockTypeContent(service, type)
  var group = getBlockTypeGroup(service, type)
  var ret = {
    content,
    group: group,
    parent: parentId,
    permissions: {
      service,
      workspace,
    },
    settings: getBlockTypeSettings(service, type),
    type,
  }
  return ret;
}

function getNearestBlockType(service, type, direction, searchingGroup) {
  //console.log("getNearestParentPage", service, type)
  var seek = function(service, type) {
    var found = []
    var searchingBlocks;
    if (direction === 'parents') {
      searchingBlocks = getBlockParentsBlockTypes(service, type);
    }
    if (direction === 'children') {
      searchingBlocks = getBlockChildrenBlockTypes(service, type);
    }
    for (var i = 0; i < searchingBlocks.length; i++) {
      var _bt = searchingBlocks[i]
      if (_bt.group === searchingGroup) {
        found = found.concat([_bt])
      } else {
        found = found.concat(seek(service, _bt.type))
      }
    }
    return found
  }
  return seek(service, type)
}

function getNearestBlockTypeLabels(service, type, direction, searchingGroup) {
  const types = getNearestBlockType(service, type, direction, searchingGroup)
  
  if (types.length > 0) {
    const typeObject = types[0];
    return getBlockTypeLabels(service, typeObject.type);
  }
  return null
}

function getPageListRouterLink(service, workspace, blockType) {
  const blockTemplates = getBlockTemplates(service, blockType);
  return {
    name: blockTemplates.list.directive,
    params: {service: service, workspace: workspace, type: blockType },
  }
}

function getTemplate(service, key) {
  var template = _.cloneDeep(config.services[service].templates[key]);
  // se il template del service manca prendo il base
  if (template === undefined) {
    template = _.cloneDeep(config.base.templates[key]);
    if (template === undefined) {
      alert("Template schema not found for type base " +key)
    }
  }
  // se c'è un riferimento ad un base
  var baseTemplate;
  if (_.isObject(template) && _.isString(template.base)) {
    baseTemplate = getTemplate(service, template.base);
  }
  if (template === undefined && baseTemplate === undefined) {
    alert("Template schema not found for type " + service + " " +key)
  }
  if (baseTemplate === undefined) {
    baseTemplate = {}
  }
  var ret = mergeDeep(baseTemplate, template)
  return ret
}

function getTypeNumInBlockType(fieldNumName, service, childType, blockType) {
  // return the fieldNumName (maxNum or minNum) of a blockType in a blockParentType
  // return undefined for no limit
  if (!_.isString(blockType)) {
    return undefined;
  }
  var childTypeConfig = getBlockChildrenConfigs(service, blockType);
  if (childTypeConfig !== undefined) {
    if (childTypeConfig[childType] !== undefined && childTypeConfig[childType][fieldNumName] !== undefined) {
      return childTypeConfig[childType][fieldNumName];
    }
  }
  return undefined; // infinite
}

const mergeDeep = (target, ...sources) => {
  const isObject = (item) => {
    return (item && typeof item === 'object' && !Array.isArray(item));
  }
  if (!sources.length) return target;
  const source = sources.shift();

  if (isObject(target) && isObject(source)) {
    for (const key in source) {
      if (isObject(source[key])) {
        if (!target[key]) Object.assign(target, {
          [key]: {}
        });
        mergeDeep(target[key], source[key]);
      } else {
        Object.assign(target, {
          [key]: source[key]
        });
      }
    }
  }
  return mergeDeep(target, ...sources);
}

export default {
  canAddChildTypeToBlockType,
  canDeleteChildTypeFromBlockType,
  config,
  getAvailableChildrenTypes,
  getBlockChildrenConfigs,
  getBlockChildrenAddNewItemsAtTop,
  getBlockChildrenRequiredTypes,
  getBlockChildrenTypes,
  getBlockParentsBlockTypes,
  getBlockTemplates,
  getBlockType,
  getBlockTypeContent,
  getBlockTypeContentMultilanguage,
  //getBlockTypeDirectiveAdmin,
  getBlockTypeDirectives,
  getBlockTypeGroup,
  getBlockTypeLabels,
  getBlockTypeSettings,
  getBlockTypeSettingsMultilanguage,
  getDefaultGalleryImageContentCategoryId,
  getEmptyBlock,
  getNearestBlockType,
  getNearestBlockTypeLabels,
  getPageListRouterLink,
  getTemplate,
  getTypeNumInBlockType,
  mergeDeep,
}