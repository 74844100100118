import { createRouter, createWebHistory } from 'vue-router'
import urls from './urls'

const Cart = () => import('KSrc/components/presentation/Cart.vue');
const CartDomain = () => import('KSrc/components/presentation/CartDomain.vue');
const LanguageDetector = () => import('KSrc/components/presentation/LanguageDetector.vue');
const Newsroom = () => import('KSrc/components/presentation/Newsroom.vue');
const NewsroomDomain = () => import('KSrc/components/presentation/NewsroomDomain.vue');

const routes = [
  {
    path: urls.spa.cart,
    name: 'cart',
    component: Cart,
    props: true,
  },
  {
    path: urls.spa.cartDomain,
    name: 'cartDomain',
    component: CartDomain,
    props: true,
  },
  {
    path: urls.spa.presentationDomain,
    name: 'presentationDomain',
    component: NewsroomDomain,
    props: true,
  },
  {
    path: urls.spa.presentation,
    name: 'presentation',
    component: Newsroom,
    props: true,
  },
  {
    path: urls.spa.presentationNoLang,
    name: 'presentationDomainNoLang',
    component: LanguageDetector,
    props: true,
  },
  /*
  {
    path: urls.spa._componentDev,
    name: 'component_dev',
    component: ComponentDevTemplate,
    props: true,
  },
  */
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})

export default router
