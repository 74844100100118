// Plugin che installa nell'app vue l'istanza di keycloak js
import * as _libsmKeycloak from 'KPackages/libsm-keycloak'

var keycloakParams = {
  url: location.protocol + '//' + process.env.VUE_APP_KEYCLOAK_DOMAIN,
  realm: process.env.VUE_APP_KEYCLOAK_REALM,
  clientId: process.env.VUE_APP_THIS_PUBLIC_CLIENT_ID,
}
const keycloakManager = new _libsmKeycloak.KeycloakManager(keycloakParams);

const Plugin = {
  install(app, options) {
    app.keycloakManager = keycloakManager
  }
}
export default Plugin

