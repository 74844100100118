import AccountApi from "KPackages/api/account/index.js"
import * as types from './mutation_types'

export function createOrUpdateSettingsInStore ({ dispatch, commit, state, getters, rootState, rootGetters }, data) {
  commit(types.CREATE_OR_UPDATE_SETTINGS, data);
}

export function readSettings ({ dispatch, commit, state, getters, rootState, rootGetters }, data ) {
  // data è { service: , workspace: }
  var serviceName = data.service;
  var workspaceName = data.workspace;
  var servicePresent = getters.settings[serviceName]
  if( servicePresent !== undefined) {
    var alreadyPresent = servicePresent[data.workspace]
    if( alreadyPresent !== undefined) {
      return Promise.resolve(alreadyPresent)
    }
  }
  var param = serviceName;
  if (serviceName === 'kseating') {
    param = workspaceName
  }
  return AccountApi.readSettings(this.$app.keycloakManager, param)
    .then(function(response) {
      var _data = {}
      _data[serviceName] = {};
      _data[serviceName][workspaceName] = response;
      dispatch('createOrUpdateSettingsInStore', _data);
      return Promise.resolve(getters.settings[serviceName][workspaceName]);
    })
    .catch(function(error) {
      // implement elsewhere to be more specific
      return Promise.reject(error);
    });
}
