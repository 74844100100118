export function generateMonolithUrl(workspaceDomain, currentUrl, params) {
  var wpDomain = location.protocol + '//' + workspaceDomain;
  // aggiunge il dominio con workspace a cui i media appartengono
  if (params === undefined) {
    return wpDomain + currentUrl;
  }
  for (var pname in params) {
    var re = new RegExp(':' + pname, 'mg');
    currentUrl = currentUrl.replace(re, params[pname])
  }
  return wpDomain + currentUrl;
}

export function getCompleteFrontendUrl(customDomain, slug, ancestorsSlugs) {
  if (customDomain !== '') {
    let ret = customDomain + '/';
    if (ancestorsSlugs.slice(2).length > 0) {
      ret += ancestorsSlugs.slice(2).join('/') + '/';
    }
    ret += slug.value;
    return ret
  } else {
    return location.protocol + '//' + location.host + '/' + ancestorsSlugs.join('/') + '/' + slug.value;
  }
}

export function getDomainCartTo(defaultLanguageCode, languageCode, blockId, workspace) {
  /*
  Generates a dict used by the "to" prop of RouterLink. The dict varies if the site
  is accessed via custom domain.
  */
  let toData = {
    params: {
      defaultLanguageCode: defaultLanguageCode,
      languageCode: languageCode,
      blockId: blockId,
    },
  };
  if (!isCustomDomain()) {
    toData.name = 'cart';
    toData.params.workspace = workspace;
  } else {
    toData.name = 'cart';
    toData.name = 'cartDomain';
  }
  return toData
}

export function getDomainPresentationTo(languageCode, pageSlug, workspace, query) {
  /*
  Generates a dict used by the "to" prop of RouterLink. The dict varies if the site
  is accessed via custom domain.
  */
  let toData = {
    params: {
      languageCode: languageCode,
      blockSlug:  [...pageSlug] //duplico array
    },
    query: query
  };

  if (isCustomDomain()) {
    toData.name = 'presentationDomain';
    // removing the first slug, it references the same block as the custom domain
    toData.params.blockSlug.shift();
  } else {
    toData.name = 'presentation';
    toData.params.workspace = workspace;
  }

  return toData
}

export function isCustomDomain() {
  const domainRegex = new RegExp('^newsroom\.(staging\.)?magnetiq\.i[ot]');
  return !domainRegex.test(window.location.hostname)
}

export default {
  generateMonolithUrl,
  getDomainCartTo,
  getDomainPresentationTo,
  isCustomDomain,
}
