// devono esser importati così per funzionare in libsm-keycloak/index.js
// NON chiamare mai l'istanza direttamente restituita dall'export default
// MA SEMPRE chiamarla da utils.js -> getAxios
import axios from '../../libsm-keycloak/axios.js';

const axiosInstance = axios.create();
axiosInstance.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
axiosInstance.defaults.withCredentials = true;
axiosInstance.defaults.xsrfCookieName = "csrftoken";
axiosInstance.defaults.xsrfHeaderName = "X-CSRFTOKEN";

//axiosInstance.interceptors.request.use patchato in KeycloakManager per il Bearer

export default axiosInstance;
