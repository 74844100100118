import _ from 'lodash'
import * as types from './mutation_types'
import * as actions from './actions'
import * as getters from './getters'

// initial state
const state = {
  languages: { /* code: Object */},
}

// mutations
const mutations = {
  [types.CREATE_OR_UPDATE_LANGUAGE] (state, data) {
    if (!_.isArray(data)) {
      data = [data]
    }
    var lang;
    for (var i = 0; i<data.length; i++) {
      lang = data[i]
      state.languages[lang.code] = lang;
    }
  },
}

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations
}
