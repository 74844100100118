import axios from '../libsm-keycloak/axios.js';
import axiosDefault from "../api/axios_defaults.js"
import axiosAccount from "../api/account/axios_defaults.js"
import axiosMedia from "../api/media/axios_defaults.js"
import axiosWebsite from "../api/website/axios_defaults.js"
import Keycloak from './keycloak-js/keycloak.js'

const magnetiqRootDomain = process.env.VUE_APP_MAGNETIQ_ROOT_DOMAIN;
if (magnetiqRootDomain === undefined) {
  let msg = "process.env.VUE_APP_MAGNETIQ_ROOT_DOMAIN not defined";
  throw(msg);
}

function getUserKeycloakGroups(tokenParsed, onlyInService) {
  // Return the tokeb group_membership key of a user in the format
  // [{ keycloak_path: <val>, service: <val>, workspace: <val> }]
  // inPath es /kseating allow to restrict the search only in that path
  if (tokenParsed.group_membership !== undefined) {
    var ret = [];
    for (var i = 0; i < tokenParsed.group_membership.length; i++) {
      var keycloak_path = tokenParsed.group_membership[i];
      var _p = keycloak_path.split('/');
      var service = _p[1];
      if (onlyInService !== undefined && service !== onlyInService) {
        continue;
      } 
      var workspace = _p[2];
      ret.push({
        keycloak_path: keycloak_path,
        service: service,
        workspace: workspace
      });
    }
    return ret;
  }
  return [];
}

function getUserKeycloakResourceAccess(tokenParsed, clientName) {
  // Return the resource_access of a user 
  return tokenParsed.resource_access[clientName].roles;
}

function isAdministatorInClient(tokenParsed, clientName) {
  if(isSuperuser(tokenParsed)) {
    return true;
  }
  let roles = getUserKeycloakResourceAccess(tokenParsed, clientName);
  if (roles.length > 0) {
    return roles[0] === 'administrator';
  }
  return false
}

function isMagnetiqUrl(url) {
  // controllo se
  // - è url relativo
  // oppure 
  // - siamo in domini o subdomini di VUE_APP_MAGNETIQ_ROOT_DOMAIN
  let reg = new RegExp("^https?:\/\/.*\." + magnetiqRootDomain.replace('.','\\.'));
  let match = reg.test(url);
  return url.startsWith('/') || match;
}

function isManagerInClient(tokenParsed, clientName) {
  let roles = getUserKeycloakResourceAccess(tokenParsed, clientName);
  if (roles.length > 0) {
    return roles[0] === 'manager';
  }
  return false
}

function isViewerInClient(tokenParsed, clientName) {
  let roles = getUserKeycloakResourceAccess(tokenParsed, clientName);
  if (roles.length > 0) {
    return roles[0] === 'viewer';
  }
  return false
}

function isStaff(tokenParsed) {
  var ret = isSuperuser(tokenParsed);
  return ret;
}

function isSuperuser(tokenParsed) {
  try {
    var ret = tokenParsed.realm_access.roles.includes('superuser');
    return ret;
  } catch (e) {
    return false;
  }
}

//function urlBase64Decode(str) {
//  // taken from angular-jwt
//  var output = str.replace(/-/g, '+').replace(/_/g, '/');
//  switch (output.length % 4) {
//    case 0: { break; }
//    case 2: { output += '=='; break; }
//    case 3: { output += '='; break; }
//    default: {
//      throw 'Illegal base64url string!';
//    }
//  }
//  return decodeURIComponent(escape(atob(output))); //polyfill https://github.com/davidchambers/Base64.js
//};

function KeycloakManager(params) {
  // Wrapper per
  // - autenticare utente
  // - memorizzare stato di authenticated per uso in vue-router che altrimenti non potrebbe avere accesso diretto all'istanza di keycloak
  // - autenticare chiamate axios creando una istanza nel keycloakManager stesso
  // 

  window.keycloakInitialized = false;
  this.authenticated = false;
  this.keycloakParams = params;
  this.keycloak = null;
  this.isStaff = false;
  this.isSuperuser = false;
  this.userInfo = null;
  
  this.axiosDefault = this.patchWithToken(axiosDefault);
  this.axiosAccount = this.patchWithToken(axiosAccount);
  this.axiosMedia = this.patchWithToken(axiosMedia);
  this.axiosWebsite = this.patchWithToken(axiosWebsite);

  // INSTANCE methods
  this.hasServiceAccess = (serviceName, workspaceName) => {
    let keycloakGroups = getUserKeycloakGroups(this.keycloak.tokenParsed);
    let go = false;
    for (var i=0; i<keycloakGroups.length; i++) {
      let groupObj = keycloakGroups[i];
      // gli adimn del workspace hanno accesso automatico anche agli altri servizi
      if(groupObj.service === 'kseating' && isAdministatorInClient(this.keycloak.tokenParsed, 'workspace-'+workspaceName)) {
        go = true;
        break
      };
      if (groupObj.service === serviceName && groupObj.workspace === workspaceName) {
        go = true;
        break;
      }
    }
    return go;
  }

  this.isInitialized = async function () {
    return new Promise(function(resolve, reject) {
      let check = setInterval(function () {
        if (window.keycloakInitialized) {
          clearInterval(check);
          resolve(true)
        }
      }, 200)
    })
  }
}

// CLASS methods
KeycloakManager.prototype.authenticateInServer = function () {
  return new Promise(async (resolve, reject) => {
    // authenticate in the django server
    const xhr = new XMLHttpRequest();
    const url = location.origin+'/keycloak-auth/authenticate-token/';
    xhr.open('POST', url);
    xhr.setRequestHeader('Authorization','Bearer ' + this.keycloak.token);
    
    xhr.onload = function onload() {
      if (this.status === 200) {
        const responseText = JSON.parse(this.responseText);
        if (responseText.reload !== undefined && responseText.reload) {
          window.location.reload();
        } else {
          resolve(true)
        }
      } else {
        reject(false)
      }
    }

    xhr.send(null)
  })
}

KeycloakManager.prototype.patchWithToken = function (axiosInstance) {
  // usato solo per vue, angular è gestito in modo differente
  var _this = this;
  axiosInstance.interceptors.request.use(async (config) => {
    if (isMagnetiqUrl(config.url) && _this.keycloak !== null && _this.keycloak.authenticated) {
      try {
        await _this.keycloak.updateToken(30);
      } catch(err) {
        return Promise.reject(err)
      }
      config.headers.Authorization = 'Bearer '+_this.keycloak.token;
    }
    return Promise.resolve(config);
  }, (error) => {
    return Promise.reject(error);
  });
  return axiosInstance
}

/*
KeycloakManager.prototype.getCookie = function (name) {
  return getCookie(name)
}
KeycloakManager.prototype.getTokenExpirationDate = function (decodedToken) {
  if (decodedToken === undefined || typeof decodedToken.exp === "undefined") {
    return null;
  }

  var d = new Date(0); // The 0 here is the key, which sets the date to the epoch
  d.setUTCSeconds(decodedToken.exp);
  return d;
}
*/

KeycloakManager.prototype.init = async function () {
  // wrapper di keycloak.init in aggiunta
  // - mette _this.authenticated a true
  // - mette a true window.keycloakInitialized
  var _this = this;
  const accountDomain = location.protocol + '//' + process.env.VUE_APP_ACCOUNT_DOMAIN
  const queryParams = new URLSearchParams(location.search);
  var redirectUri = queryParams.get('redirect_uri')
  if (redirectUri !== null) {
    redirectUri = decodeURI(redirectUri);
  }
  async function authenticate() {
    var authenticated;
    try {
      authenticated = await _this.authenticateInServer(redirectUri);
    } catch (err) {
      authenticated = false;
    }
    if (authenticated) {
      _this.isStaff = isStaff(_this.keycloak.tokenParsed)
      _this.isSuperuser = isSuperuser(_this.keycloak.tokenParsed)
      _this.userInfo = await _this.keycloak.loadUserInfo();
      if (redirectUri !== null && redirectUri !== undefined) {
        window.location.replace(redirectUri)
      }
      window.keycloakInitialized = true;
    } else {
      window.location.href = accountDomain; 
    }
  }

  return new Promise(async (resolve, reject) => {
    // prima provo col single sign on
    let ssoAuthenticated;
    _this.keycloak = new Keycloak(_this.keycloakParams);
    try {
      ssoAuthenticated = await _this.keycloak.init({
        //enableLogging: true,
        onLoad: 'check-sso',
        silentCheckSsoFallback: false, // deve esserci anche se false per evitare errori su safari
        silentCheckSsoRedirectUri: location.origin + '/sso/check/'
      })
    } catch (err) {
      ssoAuthenticated = false;
    }
    _this.authenticated = ssoAuthenticated
    if (ssoAuthenticated) {
      try {
        await authenticate()
        resolve(ssoAuthenticated)
      } catch (err) {
        alert(err)
        reject(ssoAuthenticated)
      }
    } else {
      // se non si autentica passo al login
      this.keycloak = new Keycloak(this.keycloakParams);
      var loginAutenticated = await _this.keycloak.init({
        onLoad: 'login-required',
        pkceMethod: 'S256'
      })
      if (loginAutenticated) {
        await authenticate()
        resolve(loginAutenticated)
      } else {
        window.keycloakInitialized = true;
        reject(loginAutenticated)
      }
    }
  })
}

//KeycloakManager.prototype.isTokenExpiring = function (decodedToken, millisecondsBefore) {
//  var expires = this.getTokenExpirationDate(decodedToken);
//  if (expires === null) {
//    return true;
//  }
//  var expiresMS = expires.getTime() - millisecondsBefore;
//  var currentMS = new Date().getTime();
//  //console.log(currentMS, ">", expiresMS, "expiration is", expires)
//  //console.log(new Date(currentMS), ">", new Date(expiresMS), "expiration is", new Date(expires))
//  return currentMS > expiresMS;
//}

KeycloakManager.prototype.logout = async function () {
  //// shortcut per fare logout e tornare ad account
  //// fatto con chiamata api per permettere il logout anche dal backend django
  //// dove non è accessibile il keycloakManager
  this.keycloak.logout({
    redirectUri: location.protocol + '//' + process.env.VUE_APP_ACCOUNT_DOMAIN
  })
}

export {
  getUserKeycloakGroups,
  isStaff,
  isSuperuser,
  KeycloakManager,
}
