import urls from './urls'
import { generateUrl, getAxios } from '../utils'

export default {
  readWorkspaceLanguages(keycloakManager, workspaceDomain) {
    const axiosInstance = getAxios(keycloakManager, 'axiosDefault');
    let _url = location.protocol + '//' + workspaceDomain + generateUrl(urls.server.workspace_language_list);
    return axiosInstance.get(_url)
      .then(response => {
        return Promise.resolve(response.data)
      })
      .catch(error => {
        return Promise.reject(error)
      })
  }
}
