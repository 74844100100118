import * as types from './mutation_types'
import * as actions from './actions'
import * as getters from './getters'

// initial state
const state = {
  settings: {/*
    <serviceName>: {
      <workspaceName>: { list of settings }
    }
  */}
}

// mutations
const mutations = {
  [types.CREATE_OR_UPDATE_SETTINGS] (state, data) {
    //console.log("received settings for", data)
    for (const [service, serviceData] of Object.entries(data)) {
      if (state.settings[service] === undefined) {
        //console.log("service", service, "serviceData", serviceData)
        state.settings[service] = serviceData;
      } else {
        for (const [workspace, workspaceData] of Object.entries(serviceData)) {
          //console.log("workspace", workspace, "workspaceData", workspaceData)
          state.settings[service][workspace] = workspaceData;
        }
      }
    }
  },
}

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations
}
