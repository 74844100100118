import { generateMonolithUrl } from 'KPackages/helpers/urls'

const serverUrls = {
  "media_api": '/api/media/media/:uuid',
  "media_api_read": '/api/media/read/media/:uuid',
  "media_api_list": '/api/media/media',
  "media_api_resized": '/api/media/resized/:uuid',
  "media_api_resized_read": '/api/media/read/resized/:uuid',
  "media_api_sequence": '/api/media/sequence/:uuid',
}

export default {
  addQuerystringParamsToUrl (url, obj) {
    if (url.lastIndexOf('?') === -1) {
      url += '?';
    } else {
      url += '&';
    }
    var keys = Object.keys(obj);
    for (var i=0; i<keys.length; i++) {
    url += keys[i]+'='+obj[keys[i]];
      if (i !== keys.length -1) {
        url += '&';
      }
    }
    return url
  }, 
  generateUrl (workspaceDomain, urlKey, params) {
    let currentUrl = serverUrls[urlKey];
    if (currentUrl === undefined) {
      throw("generateUrl couldn't find "+urlKey);
    }
    return generateMonolithUrl(workspaceDomain, currentUrl, params)
  },
  server: serverUrls,
};
