import media from './media'
//import sequence from './sequence'

export default {
  namespaced: true,
  modules: {
    media,
    //sequence
  }
}
