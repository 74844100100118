import * as types from './mutation_types'
import { getUserKeycloakGroups } from 'KPackages/libsm-keycloak'

export function createOrUpdateGroupsInStore ({ dispatch, commit, state, getters, rootState, rootGetters }, data) {
  commit(types.CREATE_OR_UPDATE_GROUPS, data);
}

export function readGroups ({ dispatch, commit, state, getters, rootState, rootGetters }, token ) {
  var groups = getUserKeycloakGroups(token);
  dispatch('createOrUpdateGroupsInStore', groups)
  return Promise.resolve(getters.groups);
}
