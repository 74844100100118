import * as _ from 'lodash'

export const groups = state => state.groups

export const getGroupsInService = (state, getters) => (serviceName) => {
  // returns a list of groups related to the service <serviceName>
  var ret = [];
  for (var i in getters.groups) {
    if (getters.groups[i].service === serviceName) {
      ret.push(getters.groups[i])
    }
  }
  return ret;
}

export const getDistinctWorkspaceNames = (state, getters) => () => {
  // returns the list of workspace names (es. demo, database ) present in the store (aka token)
  var ret =[];
  for (const [path, group] of Object.entries(getters.groups)) {
    if (! ret.includes(group.workspace)) {
      ret.push(group.workspace)
    }
  }
  return ret;
}