import urls from './urls'
import { generateUrl, getAxios } from '../utils'

export default {
  delete(keycloakManager, params) {
    const axiosInstance = getAxios(keycloakManager, 'axiosMedia');
    if (params.workspaceDomain === undefined) {
      throw("workspaceDomain not defined")
    }
    var url = urls.generateUrl(params.workspaceDomain, 'media_api', { 'uuid': params.uuid });
    return axiosInstance.delete(url)
    .then(function(response) {
      return Promise.resolve(response.data) 
    })
    .catch(function (error) {
      return Promise.reject(error)
    })
  },
  get(keycloakManager, params) { 
    const axiosInstance = getAxios(keycloakManager, 'axiosMedia');
    var config = {};
    if (params.workspaceDomain === undefined) {
      throw("workspaceDomain not defined")
    }
    var url = urls.generateUrl(params.workspaceDomain, 'media_api_read', { 'uuid': params.uuid });
    return axiosInstance.get(url, config)
    .then(function(response) {
      //Vue.hideThrobber(throbberId);
      return Promise.resolve(response.data) 
    })
    .catch(function (error) {
      //Vue.hideThrobber(throbberId);
      return Promise.reject(error)
    })
  },
  getResized(keycloakManager, params) { 
    const axiosInstance = getAxios(keycloakManager, 'axiosMedia');
    var config = {};
    if (params.workspaceDomain === undefined) {
      throw("workspaceDomain not defined")
    }
    var url = urls.generateUrl(params.workspaceDomain, 'media_api_resized_read', { 'uuid': params.uuid });
    
    if (typeof params.size === 'string') {
      url = urls.addQuerystringParamsToUrl(url, { size: params.size } );
    }
    if (typeof params.crop === 'string') {
      url = urls.addQuerystringParamsToUrl(url, { crop: params.crop } );
    }
    return axiosInstance.get(url, config)
    .then(function(response) {
      return Promise.resolve(response.data) 
    })
    .catch(function (error) {
      return Promise.reject(error)
    })
  },
  patch(keycloakManager, params) {
    /*
     * Throbber assente per gestire upload async nei componenti
     */
    const axiosInstance = getAxios(keycloakManager, 'axiosMedia');
    if (params.workspaceDomain === undefined) {
      throw("workspaceDomain not defined")
    }
    var url = urls.generateUrl(params.workspaceDomain, 'media_api', { 'uuid': params.formData.get('uuid') });
    const config = {
      headers: {
        'content-type': 'multipart/form-data'
      }
    }

    return axiosInstance.patch(url, params.formData, config)
    .then(function(response) {
      return Promise.resolve(response.data) 
    })
    .catch(function (error) {
      return Promise.reject(error)
    })
  },
  post(keycloakManager, params) {
    /*
     * Throbber assente per gestire upload async nei componenti
     */
    const axiosInstance = getAxios(keycloakManager, 'axiosMedia');
    if (params.workspaceDomain === undefined) {
      throw("workspaceDomain not defined")
    }
    var url = urls.generateUrl(params.workspaceDomain, 'media_api_list')
    const config = {
      headers: {
        'content-type': 'multipart/form-data'
      }
    }

    return axiosInstance.post(url, params.formData, config)
    .then(function(response) {
      return Promise.resolve(response.data) 
    })
    .catch(function (error) {
      return Promise.reject(error)
    })
  }
}

