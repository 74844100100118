import urls from './urls'
import { generateUrl, getAxios } from '../utils'

export default {
  readSettings(keycloakManager, serviceName) {
    const axiosInstance = getAxios(keycloakManager, 'axiosAccount');
    var _url = urls.generateUrl(urls.server.apiServiceSettings, { name: serviceName });
    return axiosInstance.get(_url)
    .then(function(response) {
      return Promise.resolve(response.data) 
    })
    .catch(function (error) {
      return Promise.reject(error)
    })
  }
}


