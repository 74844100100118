import { createStore, createLogger } from 'vuex'

import accountStore from 'KPackages/vuex-account/v4/store/index.js'
import mediaStore from 'KPackages/vuex-media/v4/store/index.js'
import servicesStore from 'KPackages/vuex-services/v4/store/index.js'
import websiteStore from 'KPackages/vuex-website/v4/store/index.js'
import workspaceLanguagesStore from 'KPackages/vuex-workspace-languages/v4/store/index.js'

const debug = process.env.NODE_ENV !== 'production'

export default createStore({
  modules: {
      account: accountStore,
      media: mediaStore,
      services: servicesStore,
      website: websiteStore,
      workspaceLanguages: workspaceLanguagesStore,
  },
  strict: debug,
  plugins: debug ? [createLogger()] : []
})
