import * as types from './mutation_types'
import * as actions from './actions'
import * as getters from './getters'

// initial state
const state = {
  groups: {/* keycloak_path: Object */},
}

// mutations
const mutations = {
  [types.CREATE_OR_UPDATE_GROUPS] (state, data) {
    for (const key in data) {
      var keycloak_path = data[key].keycloak_path;
      state.groups[keycloak_path] = data[key];
    };
  },
}

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations
}
