export function getImageProportions(width, height) {
  return Number(width / height);
}

export function getImageTagSizeByContainerDimensions(containerWidth, containerHeight) {
  let h = containerHeight;
  let w = containerWidth;
  let size = ''
  if (_.isString(w) && w !== "") {
    let sizeW = w;
    if (sizeW.endsWith("px")) {
      sizeW = sizeW.replace('px', '');
    }
    size += `${sizeW}`
  }
  if (_.isString(h) && h !== "") { // potrebbe esser null
    let sizeH = h
    if (sizeH.endsWith("px")) {
      sizeH = sizeH.replace('px', '')
    }
    size += `x${sizeH}`;
  }
  return size;
}

export function getProportionalHeightByWidth(width, proportions) {
  if (width === "") {
    return null;
  }
  return Math.round(Number(width) * 1 / proportions)
}

export function getProportionalWidthByHeight(height, proportions) {
  if (height === "") {
    return null;
  }
  return Math.round(Number(height) * proportions)
}
