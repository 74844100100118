import _ from 'lodash'
import * as types from './mutation_types'
import * as actions from './actions'
import * as getters from './getters'

import blockUtils from 'KSrc/components/blockUtils'

// initial state
const state = {
  blocks: {/* id: Object */},
  blocksProperties: {/* id: Object of properties */}, // proprietà dell'AdminBlock (es. dimensioni immagine di anteprima caricata)
  children: { /* id: [ block obj ] */ },
  draftStatus: { /* id: { content: bool, draft: bool (se sono draft content o anyDepthChildren), anyDepthChildren: { childId: bool } */ },
  presentationBlocks : { /* id: [ obj ] */ },
  presentationChildren: { /* id: [ presentationBlock obj ] */ },
}

// mutations
const mutations = {
  [types.CREATE_OR_UPDATE_BLOCK] (state, blocks) {
    if (!_.isArray(blocks)) {
      blocks = [blocks]
    }
    var block, presentationBlock;
    for (var i = 0; i<blocks.length; i++) {
      block = blocks[i]
      presentationBlock = block.presentationBlock
      delete block.presentationBlock

      // aggiungo possibili missing data
      block = blockUtils.addMissingData(block, state.blocks);

      // associo blocco allo state
      state.blocks[block.id] = block;
      this.commit('website/'+types.UPDATE_BLOCK_IN_CHILDREN, block.id)
      if (_.isString(block.parent)) {
        if (state.children[block.parent] === undefined) {
          state.children[block.parent] = []
        }
        // vedo se è già presente nella lista dei children
        var atIndex = _.findIndex(state.children[block.parent], (o) => { return o.id === block.id})
        if (atIndex === -1) {
          state.children[block.parent].push(state.blocks[block.id])
        } else {
          state.children[block.parent][atIndex] = state.blocks[block.id]
        }
      }
      // gestisco presentationBlock
      if (_.isObject(presentationBlock) && !_.isEmpty(presentationBlock)) {
        state.presentationBlocks[block.id] = presentationBlock
      } else {
        delete state.presentationBlocks[block.id]
      }
      // gestisco blockProperties
      if (block.type === "image" && block.settings.width !== null) {
        let blockProperties = getters.createBlockPropertiesByIdAndDimensions({
          id: block.id,
          width: block.settings.width,
          height: block.settings.height,
        })
        this.commit("website/"+types.CREATE_OR_UPDATE_BLOCK_PROPERTIES, blockProperties)
      }
    }
  },
  [types.CREATE_OR_UPDATE_BLOCK_PROPERTIES] (state, blocksProperties) {
    state.blocksProperties[blocksProperties.id] = blocksProperties
  },
  [types.CREATE_OR_UPDATE_CHILDREN_BLOCKS] (state, data) {
    var id = data.id;
    var children = data.children;
    state.children[id] = []
    for (var i = 0; i < children.length; i++) {
      this.commit("website/"+types.CREATE_OR_UPDATE_BLOCK, children[i])
    }
  },
  [types.CREATE_OR_UPDATE_PRESENTATION_BLOCK] (state, data) {
    if (!_.isArray(data)) {
      data = [data]
    }
    var block;
    for (var i = 0; i<data.length; i++) {
      block = data[i]
      state.presentationBlocks[block.id] = block;
    }
  },
  [types.CREATE_OR_UPDATE_PRESENTATION_CHILDREN_BLOCKS] (state, data) {
    var id = data.id;
    var presentationChildren = data.children;
    state.presentationChildren[id] = []
    for (var i = 0; i < presentationChildren.length; i++) {
      this.commit("website/"+types.CREATE_OR_UPDATE_PRESENTATION_BLOCK, presentationChildren[i])
    }
  },
  [types.DELETE_BLOCK] (state, obj) {
    for (const blockId in state.children) {
      var children = state.children[blockId]
      _.remove(children, function(b) {
        return b.id == obj.id
      })
    }
    this.commit('website/'+types.DELETE_BLOCK_IN_DRAFTSTATUS, obj.id)
    delete state.children[obj.id]
    delete state.blocks[obj.id]
    delete state.blocksProperties[obj.id]
  },
  [types.DELETE_BLOCK_IN_DRAFTSTATUS] (state, id) {
    delete state.draftStatus[id]
    for (const blockId in state.draftStatus) {
      if (_.has(state.draftStatus[blockId].anyDepthChildren, id)) {
        delete state.draftStatus[blockId].anyDepthChildren[id];
      }
    }
  },
  [types.SET_DRAFTSTATUS] (state, id) {
    const block = state.blocks[id]
    const presentationBlock = state.presentationBlocks[id];
    var pageId = id;
    const changed = blockUtils.areBlockDataChanged(block, presentationBlock, state.blocks);
        
    if (block.group !== 'page') {
      const pageBlock = blockUtils.seekNearestParentBlock(id, state.blocks, { group: 'page'});
      if (pageBlock === null) { // potrebbe non esser ancora stata caricata la parent
        return
      }
      pageId = pageBlock.id;
      state.draftStatus[pageId].anyDepthChildren[id] = changed
    } else {
      if (state.draftStatus[pageId] === undefined) {
        state.draftStatus[pageId] = {
          // content: bool, inizializzato subito sotto
          draft: true, // se sono draft content o anyDepthChildren
          anyDepthChildren: {}
        }
      }
      state.draftStatus[pageId].content = changed
    }
    var childrenDraft = _.pickBy(state.draftStatus[pageId].anyDepthChildren, function(is) {
      return is === true;
    })
    var isDraft = !_.isEmpty(childrenDraft) || state.draftStatus[pageId].content === true
    state.draftStatus[pageId].draft = isDraft
  },
  [types.UPDATE_BLOCK_IN_CHILDREN] (state, blockId) {
    for (var id in state.children) {
      var children = state.children[id]
      var positionChanged = false;
      var currentBlock;
      for (var i=0; i < children.length; i++) {
        var childBlock = children[i]
        currentBlock = state.blocks[blockId] 
        if (childBlock.id === blockId) {
          // controllo se è stato spostato
          // lo rimuovo dalla vecchia posizione
          // lo riaggiungerò dopo nella nuova
          if (i !== currentBlock.order) {
            positionChanged = true;
            children.splice(i, 1)
            break;
          } else {
            state.children[id][i] = state.blocks[blockId]
          }
        }
      }
      if (positionChanged) {
        children.splice(currentBlock.order, 0, currentBlock)
      }
    }
  }
}

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations
}
