import _ from "lodash"
import blockUtils from 'KSrc/components/blockUtils'
import { getImageProportions } from 'KPackages/helpers/imageUtils'

export const blocks = state => state.blocks
export const blocksProperties = state => state.blocksProperties
export const children = state => state.children
export const draftStatus = state => state.draftStatus
export const presentationBlocks = state => state.presentationBlocks

export const createBlockPropertiesByIdAndDimensions = (state, getters) => (obj) => {
  // se mancano le blockProperties per quell'is
  // restituisco l'oggetto blockProperties comprensivo delle
  // key proportions, originalHeight, originalWidth
  // altrimenti prendo queste key dalle blockProperties attuali
  // e le restituisco assieme alle nuove widht ed height
  obj = _.cloneDeep(obj)
  let found = getters.getBlockPropertiesById(obj.id); 
  if (found === undefined) {
    obj.proportions = getImageProportions(obj.width, obj.height)
    obj.originalHeight = obj.height
    obj.originalWidth = obj.width
  } else {
    obj.proportions = found.proportions;
    obj.originalHeight = found.originalHeight;
    obj.originalWidth = found.originalWidth;
  }
  return obj
}

export const getById = (state, getters) => (id) => {
  return getters.blocks[id]
}
export const getBlockPropertiesById = (state, getters) => (id) => {
  return getters.blocksProperties[id];
}
export const getChildrenById = (state, getters) => (id) => {
  return getters.children[id]
}
export const getDraftStatusById = (state, getters) => (id) => {
  return getters.draftStatus[id]
}
export const getGalleryImageIdsByCategoryId = (state, getters) => (categoryId) => {
  // get list of GalleryImageBlocks that have a content.categoryId = categoryId
  return _.filter(getters.blocks, function (b) {
    if (b.type !== 'gallery-image') {
      return false;
    }
    return _.get(b, 'content.categoryId') === categoryId;
  })
}

export const getNearestParentPageBlock = (state, getters) => (blockId) => {
  // returns the nearest parent group=page block else null
  return blockUtils.seekNearestParentBlock(blockId, getters.blocks, { 'group': 'page' })
}

export const getNearestParentBlockByProperties = (state, getters) => (blockId, properties) => {
  // returns the nearest parent group=page block else null
  return blockUtils.seekNearestParentBlock(blockId, getters.blocks, properties )
}

export const getPresentationBlockByCustomDomain = (state, getters) => (customDomain) => {
  for (let id in getters.presentationBlocks) {
    let block = getters.presentationBlocks[id];
    if (block.settings.customDomain !== undefined && block.settings.customDomain === customDomain) {
      return block;
    }
  }
  return null;
}

export const getPresentationBlockById = (state, getters) => (id) => {
  return getters.presentationBlocks[id]
}

export const getPresentationBlockBySlug = (state, getters) => (slugsString) => {
  const slugs_path = slugsString.split('/');
  
  for (var i=0; i < getters.presentationBlocks.length; i++) {
    var pb = getters.presentationBlocks[i];
    if (blockUtils.isTheSlugsPath(pb, slugs_path)) {
      return pb;
    }
  }
  return null;
}

export const isDraft = (state, getters) => (id) => {
  return getters.draftStatus[id].draft
}

export const isPublished = (state, getters) => (blockId) => {
  var block = getters.blocks[blockId];
  if (block === undefined) 
    return false
  return block.last_published !== null
}
