import ApiMediaMedia from 'KPackages/api/media/media'
import * as types from './mutation_types'

export function createOrUpdateInStore ({ dispatch, commit, state, getters, rootState, rootGetters }, data) {
  // aggiunge/modifica uno o più oggetti alla lista dello store
  // data = singolo oggetto o lista
  commit(types.CREATE_OR_UPDATE, data);
}

export function create ({ dispatch, commit, state, getters, rootState, rootGetters }, params) {
  // params is { workspaceDomain, formData }
  return ApiMediaMedia.post(this.$app.keycloakManager, params)
  .then(function(obj) {
    dispatch('createOrUpdateInStore', obj);
    return Promise.resolve(getters.all[obj.uuid]);
  })
  .catch(function(error) {
    // implement elsewhere to be more specific
    return Promise.reject(error);
  });
}

export function deleteMedia ({ dispatch, commit, state, getters, rootState, rootGetters }, params) {
  // params is { workspaceDomain, formData }
  return ApiMediaMedia.delete(this.$app.keycloakManager, params)
  .then(function(obj) {
    dispatch('createOrUpdateInStore', obj);
    return Promise.resolve(obj);
  })
  .catch(function(error) {
    // implement elsewhere to be more specific
    return Promise.reject(error);
  });
}

export function read ({ dispatch, commit, state, getters, rootState, rootGetters }, params) {
  // params: { uuid, workspaceDomain }
  // se è già stato caricato
  if (params.uuid === undefined || params.uuid === null) {
    return Promise.reject({'detail': `You're requesting a media with uuid ${uuid}`});
  }
  var uuid = params.uuid;
  if (getters.all[uuid] !== undefined) {
    return Promise.resolve(getters.all[uuid]);
  } else {
    // carica i dati dell'evento dall'api
    // e li restituisce
    return ApiMediaMedia.get(this.$app.keycloakManager, params)
    .then(function(obj) {
      dispatch('createOrUpdateInStore', obj);
      return Promise.resolve(getters.all[obj.uuid]);
    })
    .catch(function(error) {
      // implement elsewhere to be more specific
      return Promise.reject(error);
    });
  }
}

export function readResized ({ dispatch, commit, state, getters, rootState, rootGetters }, params) {
  // se è già stato caricato
  if (params.uuid === undefined || params.uuid === null) {
    return Promise.reject({'detail': `You're requesting a media with uuid ${uuid}`});
  }
  var uuid = params.uuid;
  return ApiMediaMedia.getResized(this.$app.keycloakManager, params)
  .then(function(obj) {
    return Promise.resolve(obj);
  })
  .catch(function(error) {
    // implement elsewhere to be more specific
    return Promise.reject(error);
  });
}

export function update ({ dispatch, commit, state, getters, rootState, rootGetters }, params) {
  return ApiMediaMedia.patch(this.$app.keycloakManager, params)
  .then(function(obj) {
    dispatch('createOrUpdateInStore', obj);
    return Promise.resolve(getters.all[obj.uuid]);
  })
  .catch(function(error) {
    // implement elsewhere to be more specific
    return Promise.reject(error);
  });
}

