import urls from './urls'
import { generateUrl, getAxios } from '../utils'

export default {
  createAdminBlock(keycloakManager, params) {
    const axiosInstance = getAxios(keycloakManager, 'axiosWebsite');
    var _url = generateUrl(urls.server.api_admin_blocks, {});
    return axiosInstance.post(_url, params)
    .then(function(response) {
      return Promise.resolve(response.data) 
    })
    .catch(function (error) {
      return Promise.reject(error)
    })
  },
  deleteAdminBlock(keycloakManager, id) {
    const axiosInstance = getAxios(keycloakManager, 'axiosWebsite');
    var _url = generateUrl(urls.server.api_admin_block, {id: id});
    return axiosInstance.delete(_url)
    .then(function(response) {
      return Promise.resolve(response.data) 
    })
    .catch(function (error) {
      return Promise.reject(error)
    })
  },
  editAdminBlock(keycloakManager, params) {
    const axiosInstance = getAxios(keycloakManager, 'axiosWebsite');
    var _url = generateUrl(urls.server.api_admin_block, { id: params.id });
    return axiosInstance.put(_url, params)
    .then(function(response) {
      return Promise.resolve(response.data) 
    })
    .catch(function (error) {
      return Promise.reject(error)
    })
  },
  publishBlock(keycloakManager, params) {
    const axiosInstance = getAxios(keycloakManager, 'axiosWebsite');
    var _url = generateUrl(urls.server.api_admin_publish_block, { id: params.id });
    return axiosInstance.put(_url, params)
    .then(function(response) {
      return Promise.resolve(response.data) 
    })
    .catch(function (error) {
      return Promise.reject(error)
    })
  },
  readAdminBlock(keycloakManager, idOrParams) {
    let params = idOrParams;
    if (!_.isObject(idOrParams)) {
      params = { id: idOrParams};
    }
    const axiosInstance = getAxios(keycloakManager, 'axiosWebsite');
    var _url = generateUrl(urls.server.api_admin_block, params);
    return axiosInstance.get(_url)
    .then(function(response) {
      return Promise.resolve(response.data) 
    })
    .catch(function (error) {
      return Promise.reject(error)
    })
  },
  readAdminBlockChildren(keycloakManager, params) {
    // params { id, deep }
    const axiosInstance = getAxios(keycloakManager, 'axiosWebsite');
    var _url = generateUrl(urls.server.api_admin_block_children, params);
    return axiosInstance.get(_url, { params: { deep: params.deep } })
    .then(function(response) {
      return Promise.resolve(response.data) 
    })
    .catch(function (error) {
      return Promise.reject(error)
    })
  },
  readAdminBlocks(keycloakManager, getQuerystring) {
    const axiosInstance = getAxios(keycloakManager, 'axiosWebsite');
    var _url = generateUrl(urls.server.api_admin_blocks, {});
    return axiosInstance.get(_url, { params: getQuerystring })
    .then(function(response) {
      return Promise.resolve(response.data) 
    })
    .catch(function (error) {
      return Promise.reject(error)
    })
  },
  readBlock(keycloakManager, params) {
    const axiosInstance = getAxios(keycloakManager, 'axiosWebsite');
    var serverUrl;
    if(params.id !== undefined || params.custom_domain !== undefined) {
      serverUrl = urls.server.api_block;
    } else {
      serverUrl = urls.server.api_block_by_slug;
    }
    var _url = generateUrl(serverUrl, params);
    return axiosInstance.get(_url)
    .then(function(response) {
      return Promise.resolve(response.data) 
    })
    .catch(function (error) {
      return Promise.reject(error)
    })
  },
  readBlockChildren(keycloakManager, params) {
    // params { id, deep, querystring }
    const axiosInstance = getAxios(keycloakManager, 'axiosWebsite');
    var _url = generateUrl(urls.server.api_block_children, params);
    return axiosInstance.get(_url, { params: { deep: params.deep } })
    .then(function(response) {
      return Promise.resolve(response.data) 
    })
    .catch(function (error) {
      return Promise.reject(error)
    })
  },
  readBlocks(keycloakManager, getQuerystring) {
    const axiosInstance = getAxios(keycloakManager, 'axiosWebsite');
    var _url = generateUrl(urls.server.api_blocks, {});
    return axiosInstance.get(_url, { params: getQuerystring })
    .then(function(response) {
      return Promise.resolve(response.data) 
    })
    .catch(function (error) {
      return Promise.reject(error)
    })
  },
  /*setBlockPosition(keycloakManager, params) {
    const axiosInstance = getAxios(keycloakManager, 'axiosWebsite');
    var _url = generateUrl(urls.server.api_admin_block_position, params);
    return axiosInstance.put(_url, params)
    .then(function(response) {
      return Promise.resolve(response.data) 
    })
    .catch(function (error) {
      return Promise.reject(error)
    })
  }*/
}


