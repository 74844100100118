import * as types from './mutation_types'
import { moveItemInArray } from 'KPackages/helpers/arrays'
import ApiWebsite from 'KPackages/api/website'

export function createOrUpdateBlockInStore ({ dispatch, commit, state, getters, rootState, rootGetters }, data) {
  commit(types.CREATE_OR_UPDATE_BLOCK, data);
  if (!_.isArray(data)) {
    data = [data]
  }
  // SET_DRAFTSTATUS deve stare qui perché CREATE_OR_UPDATE_BLOCK fa addMissingData
  for (let i=0; i < data.length; i++ ) {
    commit(types.SET_DRAFTSTATUS, data[i].id)
  }
}
export function createOrUpdateBlockPropertiesInStore ({ dispatch, commit, state, getters, rootState, rootGetters }, data) {
  commit(types.CREATE_OR_UPDATE_BLOCK_PROPERTIES, data);
}
export function createOrUpdateChildrenBlocksInStore ({ dispatch, commit, state, getters, rootState, rootGetters }, data) {
  commit(types.CREATE_OR_UPDATE_CHILDREN_BLOCKS, data);
  // SET_DRAFTSTATUS deve stare qui perché CREATE_OR_UPDATE_CHILDREN_BLOCK fa addMissingData
  for (let i=0; i < data.children.length; i++ ) {
    commit(types.SET_DRAFTSTATUS, data.children[i].id)
  }
}
export function createOrUpdatePresentationBlockInStore ({ dispatch, commit, state, getters, rootState, rootGetters }, data) {
  commit(types.CREATE_OR_UPDATE_PRESENTATION_BLOCK, data);
}
export function createOrUpdatePresentationChildrenBlocksInStore ({ dispatch, commit, state, getters, rootState, rootGetters }, data) {
  commit(types.CREATE_OR_UPDATE_PRESENTATION_CHILDREN_BLOCKS, data);
}
export function deleteAdminBlockInStore ({ dispatch, commit, state, getters, rootState, rootGetters }, data) {
  commit(types.DELETE_BLOCK, data);
}
export function createAdminBlock ({ dispatch, commit, state, getters, rootState, rootGetters }, payload) {
  return ApiWebsite.createAdminBlock(this.$app.keycloakManager, payload)
  .then(async function(arr) {
    // arr è una lista in cui il primo è quello creato
    // gli altri sono quelli in cui è stato aggiornato qualche field es. l'updated_at
    var block;
    for (var i = 0; i < arr.length; i++) {
    //for (var i=arr.length-1; i>=0; i--) { // faccio a rovescio perché devo prima aggiornare nello store il parent (che è l'ultimo se esiste) e poi il blocco
      var obj = arr[i];
      await dispatch('createOrUpdateBlockInStore', obj)
      if (i === 0) {
        // il blocco da restituire è sempre il prossimo
        block = obj;
      }
    }
    return Promise.resolve(getters.blocks[block.id]);
  })
  .catch(function(error) {
    // implement elsewhere to be more specific
    return Promise.reject(error);
  });
}

export function deleteAdminBlock ({ dispatch, commit, state, getters, rootState, rootGetters }, id) {
  return ApiWebsite.deleteAdminBlock(this.$app.keycloakManager, id)
  .then(async function(arr) {
    var block;
    for (var i = 0; i < arr.length; i++) {
      var obj = arr[i];
      // se è il blocco di cui avevo ricevuto il delete lo cancello
      if (obj.id === id) {
        block = obj;
        await dispatch('deleteAdminBlockInStore', obj)
      } else {
        await dispatch('createOrUpdateBlockInStore', obj)
      }
    }
    return Promise.resolve(block);
  })
  .catch(function(error) {
    // implement elsewhere to be more specific
    return Promise.reject(error);
  });
}

export async function editAdminBlock ({ dispatch, commit, state, getters, rootState, rootGetters }, block) {
  var savingBlock = _.cloneDeep(block);
  var origBlock = _.cloneDeep(getters.getById(block.id))
  // se viene eliminata una category dalla gallery
  // faccio delete delle gallery-image che hanno la stessa categoryId
  // modifico il childrenOrder del blocco togliendo i child di quella gallery
  // prima di procedere col save
  if (origBlock.type === "gallery") {
    var categoriesDeleted = [];
    var origCategories = origBlock.settings.categories;
    var categories = savingBlock.settings.categories;
    if ( origCategories.length > categories.length) {
      categoriesDeleted = _.differenceWith(origCategories, categories, _.isEqual);
      for (var c=0; c < categoriesDeleted.length; c++) {
        var cId = categoriesDeleted[c].id;
        var blocksWithCategory = getters.getGalleryImageIdsByCategoryId(cId)
        for (var bwc = 0; bwc < blocksWithCategory.length; bwc ++) {
          var delId = blocksWithCategory[bwc].id;
          await dispatch('deleteAdminBlock', delId)
          if (savingBlock.content.childrenOrder.includes(delId)) {
            _.remove(savingBlock.content.childrenOrder, (o) => {
              return o === delId;
            })
          }
        }
      }
    }
  }
  return ApiWebsite.editAdminBlock(this.$app.keycloakManager, savingBlock)
  .then(async function(obj) {
    await dispatch('createOrUpdateBlockInStore', obj)
    return Promise.resolve(getters.blocks[obj.id]);
  })
  .catch(function(error) {
    // implement elsewhere to be more specific
    return Promise.reject(error);
  });
}

export function publishBlock ({ dispatch, commit, state, getters, rootState, rootGetters }, params) {
  return ApiWebsite.publishBlock(this.$app.keycloakManager, params)
  .then(async function(arr) {
    var currentBlock;
    // arr è la lista del blocco + tutti i figli content del blocco
    for (var i = 0; i < arr.length; i++) {
      var obj = arr[i];
      if (obj.id === params.id) {
        currentBlock = obj;
      }
      await dispatch('createOrUpdateBlockInStore', obj)
    }
    return Promise.resolve(getters.blocks[currentBlock.id]);
  })
  .catch(function(error) {
    // implement elsewhere to be more specific
    return Promise.reject(error);
  });
}

export function readAdminBlock ({ dispatch, commit, state, getters, rootState, rootGetters }, id) {
  let store = this;
  if (getters.blocks[id] !== undefined) {
    return getters.blocks[id]
  }
  return ApiWebsite.readAdminBlock(this.$app.keycloakManager, id)
  .then(async function(obj) {
    await dispatch('createOrUpdateBlockInStore', obj)
    return Promise.resolve(getters.blocks[obj.id]);
  })
  .catch(function(error) {
    // implement elsewhere to be more specific
    return Promise.reject(error);
  });
}

export function readAdminBlockChildren ({ dispatch, commit, state, getters, rootState, rootGetters }, params) {
  return ApiWebsite.readAdminBlockChildren(this.$app.keycloakManager, params)
  .then(async function(children) {
    await dispatch('createOrUpdateChildrenBlocksInStore', { id: params.id, children: children })
    return Promise.resolve(children);
  })
  .catch(function(error) {
    // implement elsewhere to be more specific
    return Promise.reject(error);
  });
}

export function readAdminBlockParentOfType ({ dispatch, commit, state, getters, rootState, rootGetters }, payload) {
  /* Returns the first found parent AdminBlock of the give type
   * Payload is an object containing:
   * - "block": the starting AdminBlock
   * - "type": a string denoting the block's type to be found in the parent chain
   */

  const storeReadAdminBlock = (blockId) => dispatch('readAdminBlock', blockId);
  let currentBlock = payload.block;

  if (currentBlock.parent === null) {
    return Promise.resolve(getters.blocks[currentBlock.id]);
  }

  const loopParent = (parentId, query) => {
    return new Promise( (resolve, reject) => {
      let blockPayload = {
        id: parentId,
        querystring: payload.querystring,
      }
      storeReadAdminBlock(parentId).then(_block => {
        if (_block.type === query.type) {
          resolve(getters.blocks[_block.id])
          return
        } else {
          if (_block.parent === null) {
            resolve(null)
            return
          } else {
            resolve(loopParent(_block.parent, query))
            return
          }
        }
      });
    })
  }

  return loopParent(currentBlock.parent, payload)
}

export function readAdminBlocks ({ dispatch, commit, state, getters, rootState, rootGetters }, getQuerystring) {
  let setInStore = true;
  if (getQuerystring.setInStore !== undefined) {
    if (getQuerystring.setInStore === false) {
      setInStore = false;
    }
    delete getQuerystring.setInStore;
  }
  // return a list of root blocks of a service
  return ApiWebsite.readAdminBlocks(this.$app.keycloakManager, getQuerystring)
  .then(async function(list) {
    if (setInStore) {
      await dispatch('createOrUpdateBlockInStore', list)
    }
    return Promise.resolve(list);
  })
  .catch(function(error) {
    // implement elsewhere to be more specific
    return Promise.reject(error);
  });
}

export function readAdminBlockTreeFromRoot ({ dispatch, commit, state, getters, rootState, rootGetters }, id) {
  // ha la caratteristica che carica prima le api di tutti i parent fino alla root
  // ed esegue il createOrUpdateBlockInStore all'inverso, partendo da root fino all'id
  // questo per far funzionare correttamente l'addMissingData delle mutation
  return new Promise(async (resolve, reject) => {
    var tree = await ApiWebsite.readAdminBlock(this.$app.keycloakManager, { id: id, querystring: { from_root: true }})
    // come prima cosa devo precaricare tutti i blocchi partendo da quello root
    // così da avere sempre le lingue delle newsroom già presenti nello store
    // e disponibili per addMissingData chiamato nelle mutation dello store
    for (let i=0; i < tree.length; i++) {
      await dispatch('createOrUpdateBlockInStore', tree[i]);
    }
    resolve(tree);
  })
}


export function readBlock ({ dispatch, commit, state, getters, rootState, rootGetters }, params) {
  if (params.id !== undefined && getters.presentationBlocks[params.id] !== undefined) {
    return Promise.resolve(getters.presentationBlocks[params.id])
  }
  if(params.slugs !== undefined) {
    var sb = getters.getPresentationBlockBySlug(params.slugs);
    if (sb !== null) {
      return Promise.resolve(sb)
    }
  }
  if (params.custom_domain !== undefined) {
    var bcd = getters.getPresentationBlockByCustomDomain(params.custom_domain)
    if (bcd !== null) {
      return Promise.resolve(bcd);
    }
  }
  return ApiWebsite.readBlock(this.$app.keycloakManager, params)
  .then(async function(obj) {
    await dispatch('createOrUpdatePresentationBlockInStore', obj)
    return Promise.resolve(getters.presentationBlocks[obj.id]);
  })
  .catch(function(error) {
    // implement elsewhere to be more specific
    return Promise.reject(error);
  });
}

export function readBlockChildren ({ dispatch, commit, state, getters, rootState, rootGetters }, params) {
  return ApiWebsite.readBlockChildren(this.$app.keycloakManager, params)
  .then(async function(children) {
    let id = params.id;
    await dispatch('createOrUpdatePresentationChildrenBlocksInStore', { id, children })
    return Promise.resolve(children);
  })
  .catch(function(error) {
    // implement elsewhere to be more specific
    return Promise.reject(error);
  });
}

export function readBlockParentOfType ({ dispatch, commit, state, getters, rootState, rootGetters }, payload) {
  /* Returns the first found parent Block (not AdminBlock) of the give type
   * Payload is an object containing:
   * - "block": the starting Block (not AdminBlock)
   * - "type": a string denoting the block's type to be found in the parent chain
   */

  const storeReadBlock = (params) => dispatch('readBlock', params);
  let currentBlock = payload.block;

  if (currentBlock.parent === null) {
    return Promise.resolve(getters.presentationBlocks[currentBlock.id]);
  }

  const loopParent = (parentId, query) => {
    return new Promise( (resolve, reject) => {
      let blockPayload = {
        id: parentId,
        querystring: payload.querystring,
      }
      storeReadBlock(blockPayload).then(_block => {
        if (_block.type === query.type) {
          resolve(getters.presentationBlocks[_block.id])
          return
        } else {
          if (_block.parent === null) {
            resolve(null)
            return
          } else {
            resolve(loopParent(_block.parent, query))
            return
          }
        }
      })
      .catch(function(error) {
        reject(error)
        return
      })
    })
  }

  return loopParent(currentBlock.parent, payload)
}

export function readBlocks ({ dispatch, commit, state, getters, rootState, rootGetters }, getQuerystring) {
  // return a list of root blocks of a service
  return ApiWebsite.readBlocks(this.$app.keycloakManager, getQuerystring)
  .then(async function(list) {
    await dispatch('createOrUpdatePresentationBlockInStore', list)
    return Promise.resolve(list);
  })
  .catch(function(error) {
    // implement elsewhere to be more specific
    return Promise.reject(error);
  });
}

export function readNearestParentAdminPageBlock ({ dispatch, commit, state, getters, rootState, rootGetters }, id) {
  return new Promise(async(resolve, reject) => {
    var block = getters.blocks[id];
    if (block === undefined || _.isNull(block.parent)) { // block undefined viene a volte se un blocco è eliminato
      resolve(null)
      return // resolve da solo non esce
    }
    var parent = getters.blocks[block.parent];
    if (parent === undefined) {
      parent = await dispatch('readAdminBlock', block.parent)
    }
    if (parent.group !== 'page') {
      resolve(await dispatch('readNearestParentAdminPageBlock', parent.id))
      return
    } else {
      resolve(parent)
      return
    }
  })
}

export function readNearestParentPageBlock ({ dispatch, commit, state, getters, rootState, rootGetters }, id) {
  return new Promise(async(resolve, reject) => {
    var block = getters.presentationBlocks[id];
    if (_.isNull(block.parent)) {
      resolve(null)
      return // resolve da solo non esce
    }
    var parent = getters.presentationBlocks[block.parent];
    if (parent === undefined) {
      parent = await dispatch('readBlock', { id: block.parent})
    }
    if (parent.group !== 'page') {
      resolve(await dispatch('readNearestParentPageBlock', parent.id))
      return
    } else {
      resolve(parent)
      return
    }
  })
}


//export function readPresentationBlock ({ dispatch, commit, state, getters, rootState, rootGetters }, params) {
//  return ApiWebsite.readBlock(this.$app.keycloakManager, params)
//  .then(async function(obj) {
//    await dispatch('createOrUpdatePresentationBlockInStore', obj)
//    return Promise.resolve(getters.presentationBlocks[obj.id]);
//  })
//  .catch(function(error) {
//    // implement elsewhere to be more specific
//    return Promise.reject(error);
//  });
//}


export async function setBlockPosition ({ dispatch, commit, state, getters, rootState, rootGetters }, payload) {
  // payload = { id, from, to }
  // edits the parent block childrenOrder and persists the new position in the store
  var block = getters.blocks[payload.id];
  if (_.isString(block.parent)) {
    var parentBlock = _.cloneDeep(getters.blocks[block.parent]);
    if (parentBlock.content.childrenOrder.includes(payload.id)) {
      moveItemInArray(parentBlock.content.childrenOrder, payload.from, payload.to)
    } else {
      parentBlock.content.childrenOrder.push(payload.id)
    }
    return await dispatch('editAdminBlock', parentBlock);
  }
}

//export function setBlockStatus ({ dispatch, commit, state, getters, rootState, rootGetters }, payload) {
//  return ApiWebsite.editAdminBlock(this.$app.keycloakManager, payload)
//  .then(async function(obj) {
//    await dispatch('createOrUpdateBlockInStore', obj)
//    return Promise.resolve(getters.blocks[obj.id]);
//  })
//  .catch(function(error) {
//    // implement elsewhere to be more specific
//    return Promise.reject(error);
//  });
//}

//export function setNearestPageBlockStatus ({ dispatch, commit, state, getters }, payload) {
//  // set the block status of the nearest page block (can be also itself)
//  // payload = { id, status }
//  var currentBlock = getters.blocks[payload.id];
//  var pageBlock;
//  if (currentBlock.group === 'page') {
//    pageBlock = currentBlock;
//  } else {
//    pageBlock = getters.getNearestParentPageBlock(payload.id)
//    if (pageBlock === null) {
//      return
//    }
//  }
//  return dispatch('setBlockStatus', { id: pageBlock.id, status: payload.status })
//}

