import * as types from './mutation_types'
import ApiLanguages from 'KPackages/api/workspace-languages'

export function createOrUpdateLanguageInStore ({ dispatch, commit, state, getters, rootState, rootGetters }, data) {
  commit(types.CREATE_OR_UPDATE_LANGUAGE, data);
}

export function readLanguages ({ dispatch, commit, state, getters, rootState, rootGetters }, workspaceDomain) {
  return ApiLanguages.readWorkspaceLanguages(this.$app.keycloakManager,workspaceDomain)
    .then(function(obj) {
      dispatch('createOrUpdateLanguageInStore', obj)
      return Promise.resolve(getters.languages)
    })
    .catch(function(error) {
      return Promise.reject(error)
    })
}