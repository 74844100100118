import * as types from './mutation_types'
import * as actions from './actions'
import * as getters from './getters'

// initial state
const state = {
  all: {/* [uuid: String]: Object */}
}

// mutations
const mutations = {
  [types.CREATE_OR_UPDATE] (state, data) {
    if (Array.isArray(data) === false) {
      data = [data];
    } 
    data.forEach((media) => {
      state.all[media.uuid] = media;
    });
  },
  [types.DELETE] (state, data) {
    if (Array.isArray(data) === false) {
      data = [data];
    } 
    data.forEach((media) => {
      delete state.all[media.uuid];
    });
  },
}

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations
}

