import axios from 'KPackages/libsm-keycloak/axios'

export const addQuerystringParamsToUrl = function (url, obj) {
  if (url.lastIndexOf('?') === -1) {
    url += '?';
  } else {
    url += '&';
  }
  var keys = Object.keys(obj);
  for (var i=0; i<keys.length; i++) {
  url += keys[i]+'='+obj[keys[i]];
    if (i !== keys.length -1) {
      url += '&';
    }
  }
  return url
} 

export const generateUrl = function(currentUrl, params) {
  /* Returns an url with variables substituted using the same syntax of
   * vue-router, i.e. `:eventId` for property `eventId` passed in the 
   * `params` object parameter.
   */
  if (params === undefined) {
    return currentUrl;
  }
  for (var pname in params) {
    var re = new RegExp(':' + pname, 'mg');
    currentUrl = currentUrl.replace(re, params[pname])
  }
  if (typeof params.querystring === 'object' && params.querystring.constructor === Object) {
    currentUrl = addQuerystringParamsToUrl(currentUrl, params.querystring);
  }
  return currentUrl;
}

export const getAxios = function(keycloakManager, keycloakManagerAxiosInstance) {
  // per permettere autenticazione delle chiamate axios è stato necessario
  // creare diverse istanze di axios in keycloakManager che aggiungono il bearer token
  // e interceptors a seconda delle api
  // se presente quindi keycloakManager restitutisce l'istanza richiesta con keycloakManagerAxiosInstance (axiosDefault, se mancante)
  // altrimenti (es. in presentation) restituisce axios direttamente, senza bearer;
  if (keycloakManager === undefined) {
    return axios
  }
  if (keycloakManagerAxiosInstance === undefined) {
    throw new Error ("keycloakManagerAxiosInstance param missing in getAxios, please pass one of the axios... instances present in keycloakManager")
  }
  var ret = keycloakManager[keycloakManagerAxiosInstance];
  if (ret === undefined) {
    throw new Error (keycloakManagerAxiosInstance,"property undefined in keycloakManager")
  }
  return ret;
}


export default {
  addQuerystringParamsToUrl,
  generateUrl,
  getAxios,
};
