import { generateMonolithUrl as baseGenerateMonolithUrl } from 'KPackages/helpers/urls'
import { addQuerystringParamsToUrl } from 'KPackages/api/utils'

const serviceDomain = process.env.VUE_APP_NEWSROOM_DOMAIN;
if (serviceDomain === undefined) {
  throw("process.env.VUE_APP_NEWSROOM_DOMAIN not defined")
}
const serviceUrl = location.protocol + '//' + serviceDomain;

const monolithUrls = { // url di django
  //apiServiceSettings: '/api/service/:name/settings/',
  //dashboard: '/',
  //logout: 'logout',
  mediaDownload: '/media/:mediaUuid/download'
} 

const spaUrls = { // url vue
  _componentDev: '/:workspace(\\w{3,})/p/_component_dev',
  cart: '/:workspace(\\w{3,})/:languageCode(\\w{2})/selection/:blockId',
  cartDomain: '/:languageCode(\\w{2})/selection/:blockId',
  presentationDomain: '/:languageCode(\\w{2})/:blockSlug*',
  presentation: '/:workspace(\\w{3,})/:languageCode(\\w{2})/:blockSlug*',
  presentationNoLang: '/:blockSlug*',
}

const generateMonolithUrl = (workspaceDomain, urlKey, params) => {
  let currentUrl = monolithUrls[urlKey];
  if (currentUrl === undefined) {
    throw("generateUrl couldn't find "+urlKey);
  }
  return baseGenerateMonolithUrl(workspaceDomain, currentUrl, params)
}

const generateSlugsSpaUrl = (workspace, spaUrl, languageCode, blockSlug, querystring) => {
  if (workspace !== undefined) {
    blockSlug = workspace + '/' + blockSlug;
  }
  let params = {
    'languageCode': languageCode,
    'blockSlug': blockSlug,
  };
  if (workspace !== undefined) {
    params.workspace = workspace;
  }
  if (_.isObject(querystring)) {
    params['querystring'] = querystring;
  }
  return generateUrl(spaUrls[spaUrl], params)
}

const generateUrl = function(requestedUrl, params) {
  /* Returns an url with variables substituted using the same syntax of
   * vue-router, i.e. `:eventId` for property `eventId` passed in the 
   * `params` object parameter.
   */

  let currentUrl = requestedUrl.replace(/[\+\*]/, '').replace(/\(.*\)/, '');

  if (params === undefined) {
    return currentUrl;
  }
  for (var pname in params) {
    var re = new RegExp(':' + pname, 'mg');
    // remove + for multiple items
    currentUrl = currentUrl.replace(re, params[pname]);
  }
  if (_.isObject(params.querystring)) {
    currentUrl = addQuerystringParamsToUrl(currentUrl, params.querystring);
  }
  return currentUrl;
}

const getSlugArrayAsString = (arr) => {
  return arr.join('/')
}

export default {
  generateMonolithUrl: generateMonolithUrl,
  generateSlugsSpaUrl: generateSlugsSpaUrl,
  generateUrl: generateUrl,
  getSlugArrayAsString: getSlugArrayAsString,
  spa: spaUrls
};
