const serviceDomain = process.env.VUE_APP_ACCOUNT_DOMAIN;
if (serviceDomain === undefined) {
  throw("process.env.VUE_APP_ACCOUNT_DOMAIN not defined")
}
const serviceUrl = location.protocol + '//' + serviceDomain;

const serverUrls = { // url di django
  apiServiceSettings: serviceUrl + '/api/service/:name/settings/',
  dashboard: serviceUrl + '/',
  logout: serviceUrl + '/',
} 

const spaUrls = { // url vue
  dashboard: '/',
  dashboard_staff: '/staff'
}

const generateUrl = function(currentUrl, params) {
  /* Returns an url with variables substituted using the same syntax of
   * vue-router, i.e. `:eventId` for property `eventId` passed in the 
   * `params` object parameter.
   */
  if (params === undefined) {
    return currentUrl;
  }
  for (var pname in params) {
    var re = new RegExp(':' + pname, 'mg');
    currentUrl = currentUrl.replace(re, params[pname])
  }
  if (_.isObject(params.querystring)) {
    currentUrl = addQuerystringParamsToUrl(currentUrl, params.querystring);
  }
  return currentUrl;
}

export default {
  generateUrl: generateUrl,
  server: serverUrls,
  spa: spaUrls
};
