export function removeObjectFromArrayByVal(arr, path, val) {
  for( var i = 0; i < arr.length; i++){
    var obj = arr[i];
    if (_.get(obj, path) === val) { 
      arr.splice(i, 1); 
    }
  }
};

export function moveItemInArray(arr, oldIndex, newIndex) {
  if (newIndex >= arr.length) {
    let i = newIndex - arr.length + 1;
    while (i--) {
      arr.push(undefined);
    }
  }
  arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0]);
  return arr;
};

export default {
    moveItemInArray,
    removeObjectFromArrayByVal,
}
